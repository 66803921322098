<template>
    <div class="content">
        <!-- 头部 -->
        <header>
            <img onclick="window.history.go(-1)" src="../../assets/img/go.png" alt="">
            <span style="padding-left: 33%" class="fs">最美课本</span>
        </header>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p class="fb">最美课本</p>
        </div>
        <!-- 第三部分 -->
        <div class="list_video">
            <div class="list_videos">
                <div class="video_title">
                    <p class="video_hs fb">最美课本宣传片</p>
                    <p class="video_text fs">国内领先的美育融合化学习平台</p>
                </div>
                <ul>
                    <li>
                        <div class="video_one">
                            <div class="video_bg">
                                <p class="video_img" @click="playVideo" v-show="!isShow">
                                    <span class="video_play"></span>
                                </p>
                                <div class="video_open" v-show="isShow">
                                    <video
                                        class="videoPlayer"
                                        ref="videoPlayer"
                                        preload
                                        disablepictureinpicture
                                        controlslist="nodownload"
                                        controls
                                        src="http://file.zjx.com/mp4/1568712089502.mp4">
                                    </video>
                                </div>
                            </div>
                            <p class="fs video_info">将传统艺术、创新艺术、非遗、国粹等超过200种艺术表现形式融入课本，给学生呈现一种多元艺术的、趣味的视频化学习场景 全面培养学生的相像力、个性化思维能力和创造力</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 尾部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isShow: false
        };
    },
    mounted() {
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            this.$router.push('/classroom')
        } else {
            window.addEventListener('resize', this.remScale());

        }
    },
    methods: {
        playVideo() {
            this.isShow = true;
            this.$refs.videoPlayer.play();
        },
        remScale() {
            var winW = document.documentElement.clientWidth;
            winW = winW > 750 ? 750 : winW;
            document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../assets/css/classroom_en.scss";
</style>
